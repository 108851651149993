<template>
	<v-sheet class="mt-2">
			<v-row>
				<v-col>
					<h2>Tags</h2>
					<p>You can optionally include one or more tags as part of your staff survey link to track responses from specific groups or in connection with specific events. Included tags will be saved with your survey responses, and you can view survey results by tag on the results dashboard.</p>
					<p>To include a tag, either select one or more existing tags below, or create a new tag. </p>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card outlined>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="8">
									<v-container>
										<v-row>										
											<v-col style="min-height: 170px"> 
												<h3 class="mb-3">Select Tags to Include (Optional)</h3>
												<p>Your existing tags are available to select from below. Toggle which tags you’d like to include by clicking on the tag. Your staff survey link will update automatically. </p>
												<template v-if="tags.length">
													<v-item-group v-model="selectedTags" multiple>
														<v-item v-slot="{toggle, active}"  v-for="tag in tags" :key="tag.name" :value="tag.id">
															<tag-chip @click="toggle" :active="active" v-bind="tag"></tag-chip>
														</v-item>
													</v-item-group>
													<v-btn class="mt-5" color="secondary" small text v-if="selectedTags.length" @click="selectedTags = []">
														Clear Selection
													</v-btn> 
												</template>
												<template v-else>
													<v-alert type="info" text>
														No tags found. Create your first tag now
													</v-alert>
												</template>
											</v-col>
										</v-row>
									</v-container>
								</v-col>
								<v-col>
									<new-tag></new-tag>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<tag-link :tags="selectedTags"></tag-link>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
	</v-sheet>
</template>

<script>
	import TagChip from '@c/staff-survey-tagging/TagChip.vue';
	import NewTag from '@c/staff-survey-tagging/NewTag.vue';
	import TagLink from '@c/staff-survey-tagging/TagLink.vue';
	// import RenderContent from "@c/ui/RenderContent";
	export default {
		name: "StaffSurveyTags",
		data: () => {
			return {
				selectedTags: []
			};
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data.id;
			},
			tags() {
				return this.$store.getters["staffSurveyTags/forOrganisation"](this.organisation);
			},
			staffSurveyId() {
				return this.$store.state.config.data["staff_survey"];
			},
			section() {
				return this.$store.state.sections.data[this.staffSurveyId];
			},
		},
		components: {
			TagChip, 
			NewTag,
			TagLink
			// RenderContent,
		},
		watch: {
			organisation: {
				immediate: true,
				handler(value) {
					if (value) {
						this.$store.dispatch("staffSurveyTags/openDBChannel", {
							clauses: {
								where: [["organisation", "==", value]]
							}
						});
					}
				},
			},
		},
		methods: {
		}
	};
</script>
