<template>
	<v-card flat>
		<v-card-text>
			<v-simple-table>
				<thead>
					<tr>
						<th>Tag</th>
						<th>Count</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="tag in tags" :key="tag.id">
						<td>{{ tag.name }}</td>
						<td>{{ count(tag.id) }}</td>
					</tr>
					<tr>
						<td>No Tags</td>
						<td>{{responsesWithoutTags.length}}</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		name: "CountResultsByTag",
		props: {
			responsesInDateRange: {type: Array}
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data.id
			},
			tags() {
				return this.$store.getters["staffSurveyTags/forOrganisation"](this.organisation);
			},
			responsesWithTags(){
				return Object.values(this.responsesInDateRange).filter(a => a.tags && a.tags.length );
			},
			responsesWithoutTags(){
				return Object.values(this.responsesInDateRange).filter(a => !a.tags || !a.tags.length );
			}
		},
		methods: {
			count(j) {
				return this.responsesWithTags.filter((a) =>
					a.tags.includes(j)
				).length;
			}
		}
	};
</script>
