<template>
    <v-tooltip top>
        <template v-slot:activator="{on}">
            <v-chip v-on="on" :color="active ? 'success' : 'primary'" class="mr-2" @click="$emit('click')">
                {{name}}
            </v-chip>
        </template>
    {{description}}
    </v-tooltip>
</template>

<script>
export default {
    name: "TagChip",
    props: {
        name: String,
        description: String, 
        id: String, 
        active: Boolean
    }
}
</script>