<template>
	<v-card flat>
        <v-card-text>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="jf in services" :key="jf.id">
                        <td>{{ jf.name }}</td>
                        <td>{{ count(jf.id) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "CountResultsByService",
	props: {
		responsesInDateRange: { type: Array }
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},		
		providerType() {
			const id = this.organisation.provider[0];
			if( !id ){
				return null;
			}
			return this.$store.state.categoryOptions.data[id]
		},
		serviceOptions() {
			return this.providerType ? this.providerType.services : []
		},
		services(){
			const self = this;
			return self.serviceOptions.map( option => self.categoryOptions[option])
		},

	},
	methods: {
		count(j) {
			return Object.values(this.responsesInDateRange).filter(
				(a) => a["service"].includes(j)
			).length;
		},
	},
};
</script>
