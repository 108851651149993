<template>
    <v-card flat color="lightest">
        <v-form ref="form" v-model="valid">    
            <v-card-title>Create New Tag</v-card-title>
            <v-card-text>
                <p>You can use this form to set up a new tag. Please consider carefully whether you could use an existing tag before you set up a new one; duplicating tags can make data analysis more difficult and render any findings less insightful!</p>
                <v-text-field v-model="name" outlined persistent-hint :rules="required" rounded label="Tag Name" hint="Spaces and special characters will be removed">
                </v-text-field>
                <v-textarea  v-model="description" outlined persistent-hint :rules="required" rounded label="Tag Description">
                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="reset">Clear</v-btn>
                <v-btn color="success" @click="submit" :disabled="!valid">Submit</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>


<script>


export default {
    name: "NewTag",
    data: () => {
        return {
            name: null, 
            description: null,
            valid: null, 
            required: [
                v => !!v || 'Required'
            ],

        }
    }, 
    computed: {
        organisation( ) {
            return this.$store.state.organisation.data
        }
    }, 
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        submit(){
            let tag = {
                name: this.mwutils.strToKey(this.name), 
                description: this.description,
                organisation: this.organisation.id
            }
            this.$store.dispatch("staffSurveyTags/insert", tag).then( () => {
                this.reset();
            })
        }
    }
}
</script>