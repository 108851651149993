<template>
    <v-alert v-if="organisation" color="primary" text class="pa-3 mt-5" style="width: 100%"> 
        <h3>Copy Your Staff Survey Link</h3>
        <p>The link below updates automatically to include the tags selected above.</p>
        <p>To distribute your staff survey, click on the icon to copy the link. You can then paste the link into your medium of choice (Email, newsletter etc). </p>
        <span>{{ link }}</span>
        <copy-button :text="link" style="float: right"></copy-button>
    </v-alert>
</template>

<script>
import CopyButton from "@c/buttons/CopyButton.vue";
export default {
	name: "StaffSurveyLinkWithTags",
    props: {
        tags: {type: Array, default: () => ([])}
    },
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
        tagString(){
            if( this.tags.length ){
                return "?tags=" + this.tags.join(",")
            }
            return "";
        },
		link() {
			return `${window.location.origin}/staff-survey/${this.organisation}${this.tagString}`;
		},		
	},
	components: { CopyButton },
};
</script>
