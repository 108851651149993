<template>
	<v-sheet>
		<h2 class="mb-4">Staff Survey</h2>
			<p>The staff survey is an easy to use way of sharing a digital maturity survey with your organisation’s workforce:
			</p>
			<ul class="mb-5">
				<li>
					<b>Just copy and distribute the survey link</b>, for example by email, intranet or any other method you see fit. Members of staff can use the link to access and respond to the survey anonymously. 
				</li>
				<li>
					<b>See results readily summarised</b> on <router-link to="/staff-survey/responses">this page</router-link>, or download your data for further analysis
				</li>
				<li>
					Optionally, <b>add tags</b> to the survey link for more detailed results tracking
				</li>
				<li>
					Optionally, add <b>up to 5 user-generated questions</b> from our question library
				</li>
			</ul>
			<p>
			The staff survey module is designed to support your Digital Maturity Assessment and your organisation’s digital transformation work; for example:
			</p>
			<ul class="mb-5">
				<li>
					Support your completion of the Digital Maturity Assessment by providing insights into staff perceptions on specific, predefined topics from the assessment 
				</li>
				<li>
					Gauge progress and highlight differences by service and staff group
				</li>
				<li>
					Help you detect the effects of digital transformation initiatives via our standardised set of benefits tracking indicators
				</li>
			</ul>
			<h2>Generate Staff Survey Link</h2>
			<p>You can generate your staff survey link for sharing with your organisation's workforce below. To distribute the survey, simply copy and paste the link into your medium of choice (E.g., email, intranet page, newsletter etc). Anyone with the link can access and complete your organisation’s staff survey anonymously.</p>
			<v-btn color="primary" router-link to="/staff-survey/questions">Add Optional Questions From Question Library</v-btn>
			<staff-survey-tags></staff-survey-tags>
	</v-sheet>
</template>

<script>
import StaffSurveyTags from "@c/staff-survey-tagging/StaffSurveyTags.vue";
export default {
	name: "StaffSurveyDashboard",
	data: () => {
		return {

		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		staffSurveyId() {
			return this.$store.state.config.data["staff_survey"];
		},
		section() {
			return this.$store.state.sections.data[this.staffSurveyId];
		},
	},
	components: {
		StaffSurveyTags,
	},
	watch: {
		organisation: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("staffSurveyResponses/openDBChannel", {
						clauses: {
							where: [["organisation", "==", value]],
						},
					});
				}
			},
		},
		staffSurveyId: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("sections/fetchById", value);
				}
			},
		},
	},
	created() {
		this.$store.dispatch("jobFunctions/openDBChannel");
		this.$store.dispatch("config/start", "default");
	},
};
</script>
